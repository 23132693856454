import type { User } from "@prisma/client";
import { SellerVisibility } from "@prisma/client";
import { Avatar } from "antd";
import * as React from "react";

const SATURATION = 60;
const LIGHTNESS = 80;

/** stringToHslColor generates a deterministic hue from the input str and then
 * adds saturation and lightness to make an HSL color string.
 */
const stringToHslColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return "hsl(" + h + ", " + SATURATION + "%, " + LIGHTNESS + "%)";
};

function UserAvatar({
  name,
  letter,
  color,
  shape = "square",
}: {
  name: string;
  letter?: string;
  color?: string;
  shape?: React.ComponentProps<typeof Avatar>["shape"];
}) {
  const backgroundColor = color ?? stringToHslColor(name);
  const avatarLetter = letter ?? name[0];

  return (
    <div className="Seller">
      <Avatar shape={shape} style={{ backgroundColor, marginRight: "8px" }}>
        {avatarLetter}
      </Avatar>
      {name}
    </div>
  );
}

export function SellingUser({
  sellerVisibility,
  user,
}: {
  sellerVisibility?: SellerVisibility;
  user: Pick<User, "company" | "firstName" | "lastName"> | null;
}) {
  if (sellerVisibility === SellerVisibility.PRIVATE) {
    return <UserAvatar name="Anonymous" color="grey" />;
  }

  if (!user) {
    return <UserAvatar name="Unknown user" letter="?" color="grey" />;
  }

  if (sellerVisibility === SellerVisibility.COMPANY && user.company) {
    return <UserAvatar name={user.company} />;
  }

  const name = user.firstName + " " + user.lastName;
  return <UserAvatar name={name} />;
}

export default function UserIcon({
  user,
}: {
  user: Pick<User, "company" | "firstName" | "lastName"> | null;
}) {
  if (!user) {
    return (
      <UserAvatar shape="circle" name="Unknown user" letter="?" color="grey" />
    );
  }

  const name = user.firstName + " " + user.lastName;
  return <UserAvatar shape="circle" name={name} />;
}
